import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "./Button"

import { container, imageContainer } from "./sectionCard.module.css"

const SectionCard = ({ name, path, image }) => {
  return <div className={`${container} flex flex-col rounded-lg border-yellow border-t-8`}>
    <GatsbyImage
      image={image}
      alt={name}
      className={imageContainer}
    />

    <div className="text-center py-4">
      <Button to={path} text={name} />
    </div>
  </div>;
};

export default SectionCard;
