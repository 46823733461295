import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"
import SectionCard from "../../components/SectionCard"

const query = graphql`
  {
    libreria: file(base: {eq: "libreria.png"}, sourceInstanceName: {eq: "assets"}) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH,
          transformOptions: {
            cropFocus: NORTH,
            fit: COVER
          },
        )
      }
    },
    cineteca: file(base: {eq: "cineteca.webp"}, sourceInstanceName: {eq: "assets"}) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH,
          transformOptions: {
            cropFocus: NORTH,
            fit: COVER
          },
        )
      }
    },
  }
`;

const PiccoliSollieviPage = () => {
  const data = useStaticQuery(query);

  return <Layout>
    <Seo
      title="Piccoli Sollievi"
      description="Se la nostra vita è una storia di cui noi siamo il protagonista, allora il cinema e la letteratura possono trasformarsi in un farmaco. Per questo motivo vi propongo alcuni spunti per trattare le nostre ferite interne"
    />
    <Hero title="Piccoli Sollievi" />
    <section className="grid grid-cols-6 py-6 gap-y-4 gap-x-4">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
        <p>
          Se la nostra vita è una storia di cui noi siamo il protagonista, allora il <b>cinema</b> e la <b>letteratura</b> possono trasformarsi in un farmaco.
          Per questo motivo vi propongo alcuni spunti per trattare le nostre ferite interne, consapevoli comunque del fatto che,
          come scrive Franco Arminio "<i>bisogna ricordarsi che nell'uomo c'è un bisogno di salute e c'è un bisogno di malattia</i>".
          Ha senso stare bene ma ha senso anche stare male.
          Kafka una volta scrisse questa frase: "<i>Mi ruppi una gamba. Fu il più bel giorno della mia vita.</i>".
          Che cosa significa?
          Significa che a volte il <b>sintomo</b> - paradossalmente - è un regalo che il nostro io più intimo ci fa per segnalarci di
          riprendere in mano la nostra esistenza.
        </p>
      </article>
      <article className="col-span-6 md:col-start-2 md:col-span-2 px-5 md:p-0">
        <SectionCard
          name="Libreria"
          path="/piccoli-sollievi/libreria"
          image={data.libreria.childImageSharp.gatsbyImageData}
        />
      </article>
      <article className="col-span-6 md:col-span-2 px-5 md:p-0">
        <SectionCard
          name="Cineteca"
          path="/piccoli-sollievi/cineteca"
          image={data.cineteca.childImageSharp.gatsbyImageData}
        />
      </article>
    </section>
  </Layout>;
};

export default PiccoliSollieviPage;
